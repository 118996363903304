<app-nav-menu></app-nav-menu>
<br>
<br>
<section class="success" id="Intro">
    <div class="container">
        <div class="row">
            <div class="terminal">
                <div class="css-typing">
<!--                     <p>
                        <span id="a">KS@Build</span>:<span id="b">~</span>
                        <span id="c">$</span>
                        git init<br>
                    </p>
                    <p>
                        <span id="a">KS@Build</span>:<span id="b">~</span>
                        <span id="c">$</span>
                        echo Hello >> AboutKristy.md<br>
                    </p>
                    <p>
                        First off,
                        <span id="c">Tell your dog I said Hi!</span>
                    </p>

                    <p>
                        I enjoy helping teams deliver software from source control to end-users.<br>
                    </p>

                    <p>
                        I am currently a DevOps Engineer by day and a Computer Science student by night.<br>
                    </p>

                    <p>
                        You can view my work experience here:
                        <a href="https://www.linkedin.com/in/kristy-stallings/">LinkedIn</a>.<br>
                    </p>

                    <p>
                        Feel free to send me an email at<a href="KristyStallings@outlook.com">
                            KristyStallings@outlook.com</a>.<br>
                    </p>

                    <p>
                        <span id="a">KS@Build</span>:<span id="b">~</span>
                        <span id="c">$</span>
                        git add AboutKristy.md<br>
                    </p>

                    <p>
                        <span id="a">KS@Build</span>:<span id="b">~</span>
                        <span id="c">$</span>
                        git commit -m "add DevOps enthusiast that loves dogs, books, and coffee."
                    </p> -->
                    <p>
                        <span id="a">Work in progress</span>
                    </p>

                </div>
            </div>


            <!-- About Section -->
            <section class="success" id="About">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 text-center">
                            <h2></h2>
                        </div>
                    </div>
                    <br>
                    <br>
                    <br>
