

<div class="header">
  <div class="logo">
    <span style="color:#000000">Kristy</span>
    <span style="color:#1FDFEF"> Stallings</span>
  </div>
  <div class="menu">
    <a href="#" class="link">
      <a href="https://github.com/kstallings">
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
width="40" height="40"
viewBox="0 0 172 172"
style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#1fdfef"><path d="M157.66667,86c0,32.10667 -21.07,59.26833 -50.16667,68.37v-18.20333c0,-6.1275 -2.58,-11.61 -6.665,-15.55167c18.77667,-3.40417 28.165,-14.33333 28.165,-34.615c0,-8.77917 -1.79167,-15.73083 -5.30333,-21.14167c1.57667,-6.1275 2.50833,-14.835 -1.86333,-21.85833c-8.45667,0 -14.36917,4.98083 -17.845,9.06583c-5.19583,-1.25417 -11.18,-1.89917 -17.98833,-1.89917c-6.45,0 -12.39833,0.71667 -17.70167,2.18583c-3.44,-4.12083 -9.46,-9.3525 -18.13167,-9.3525c-5.08833,8.17 -3.01,16.985 -1.075,21.93c-3.87,5.41083 -6.09167,12.3625 -6.09167,21.07c0,20.28167 9.38833,31.21083 28.165,34.615c-2.40083,2.32917 -4.26417,5.16 -5.41083,8.385h-8.42083c-5.16,0 -7.16667,-2.29333 -9.92583,-6.02c-2.75917,-3.72667 -5.73333,-6.235 -9.28083,-7.27417c-1.89917,-0.215 -3.18917,1.32583 -1.505,2.6875c5.62583,4.04917 6.02,10.67833 8.2775,15.01417c2.0425,3.90583 6.27083,6.3425 11.03667,6.3425h8.56417v14.62c-29.09667,-9.10167 -50.16667,-36.26333 -50.16667,-68.37c0,-39.59583 32.07083,-71.66667 71.66667,-71.66667c39.59583,0 71.66667,32.07083 71.66667,71.66667z"></path></g></g></svg>
      </a>
      <div class="bar"></div>
    </a>
    <div class="bar"></div>
    <a href="#" class="link">
      <a href="https://www.linkedin.com/in/kristy-stallings/">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="35" height="35"
        viewBox="0 0 172 172"
        style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#1fdfef"><path d="M156.23893,0h-140.47786c-8.5944,0 -15.76107,7.16667 -15.76107,15.76107v140.47786c0,8.5944 7.16667,15.76107 15.76107,15.76107h140.47786c8.5944,0 15.76107,-7.16667 15.76107,-15.76107v-140.47786c0,-8.5944 -7.16667,-15.76107 -15.76107,-15.76107zM50.16667,143.33333h-28.66667v-78.83333h28.66667zM35.83333,55.17774c-8.5944,0 -14.33333,-5.73893 -14.33333,-12.9056c0,-7.86654 5.73893,-13.60547 14.33333,-13.60547c8.5944,0 14.33333,5.73893 14.33333,12.9056c0,7.86654 -5.73893,13.60547 -14.33333,13.60547zM150.5,143.33333h-28.66667v-43c0,-11.47786 -7.89453,-14.33333 -10.02213,-14.33333c-2.1556,0 -11.47787,1.42774 -11.47787,14.33333c0,1.42774 0,43 0,43h-28.66667v-78.83333h28.66667v11.47787c4.3112,-6.4668 11.47787,-11.47787 25.08333,-11.47787c13.60547,0 25.08333,10.75 25.08333,35.83333z"></path></g></g></svg>
      </a>
      <div class="bar"></div>
    </a>
  </div>
</div>


